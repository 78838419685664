.Footer {
  position: static;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.Footer a {
  color: var(--primary-color);
  font-size: 22px;
}

.Footer .email {
  margin-right: 12px;
}

.Contact section {
  text-align: center;
  margin-top: 60px;
}

.Contact .emailLink,
.instaLink {
  color: white;
}

.Contact .emailLink:hover,
.instaLink:hover {
  color: white;
}

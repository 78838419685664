.Home header {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("/public/images/garden-of-the-gods.jpg");
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  padding-top: 210px;
  padding-bottom: 210px;
}

.Home header h1 {
  text-align: center;
  font-size: 75px;
  letter-spacing: 5px;
  line-height: 1.25;
  color: white;
}

.Home header h1 span {
  font-weight: lighter;
}

.Home header img {
  display: block;
  margin: 0 auto;
  width: 50%;
}

.Home iframe {
  display: block;
  margin: 50px auto;
}

.Home h5 {
  display: block;
  margin: 0 auto;
  text-align: center;
  font-size: 18px;
  margin-top: 60px;
  width: 75%;
}

.Home .about {
  background-color: var(--primary-color);
  text-align: center;
  font-size: 22px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.Home .about p {
  display: 0 auto;
  margin: 0 auto;
  width: 80%;
}

.Home .legend {
  text-align: center;
  font-size: 12px;
}

.Home .carrot {
  width: 20px;
}

.Home .contactUs {
  color: var(--primary-color);
}

@media (max-width: 768px) {
  .Home header {
    width: 100%;
    padding: 50px;
  }

  .Home header h1 {
    font-size: 36px;
  }

  .Home header img {
    width: 100%;
  }

  .Home iframe {
    max-width: 100%;
  }

  .Home h5 {
    font-size: 14px;
  }

  .Home .about {
    font-size: 18px;
  }
}

.About section {
  margin-top: 60px;
}

.About img {
  border-radius: 5px;
  margin: 0 auto;
}

.About h3 {
  color: var(--primary-color);
  font-weight: bold;
  font-size: 32px;
  text-decoration: none;
  margin-top: 30px;
}

.About .aboutPhoto {
  display: flex;
  aspect-ratio: 4/3;
}

.About .title {
  display: block;
  margin: 0 auto;
  font-weight: bold;
  color: var(--primary-color);
  background-color: white;
  border-radius: 5px;
  width: fit-content;
  padding: 5px;
}

.About .paragraph {
  display: block;
  margin: 0 auto;
  width: 75%;
  margin-top: 30px;
  text-align: justify;
}

.About .aboutGrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
  text-align: center;
  padding: 10px;
}

@media (max-width: 768px) {
  .About .aboutGrid {
    display: block;
  }

  .About .paragraph {
    margin-bottom: 50px;
  }
}

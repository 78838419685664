:root {
  --primary-color: #5c4485;
  --secondary-color: #71ab90;
  --third-color: #fec268;
  --fourth-color: #ccd487;
  --fifth-color: #ec6171;
}

body {
  background-color: var(--secondary-color);
  font-family: "Hanken Grotesk", sans-serif;
  color: white;
}

h1 {
  text-align: center;
  margin-top: 60px;
  color: var(--primary-color);
  font-weight: 700;
  letter-spacing: 5px;
}

h3 {
  text-align: center;
  text-decoration: underline;
  font-size: 26px;
}

.disclaimer {
  text-align: center;
}
